<template>
  <div class="answer-item" v-loading="loading">
    <el-popover
      placement="right"
      trigger="click"
      width="300"
      popper-class="custom-answer-popover"
      v-if="answerData.length > 0"
    >
      <div>
        <div
          style="
            width: 300;
            max-height: 200px;
            overflow-x: hidden;
            overflow-y: auto;
          "
          v-html="answerData[0].content"
          :title="answerData[0].star ? '精华回答' : ''"
        ></div>
      </div>
      <div class="table-answer" slot="reference">
        <i
          class="el-icon-star-on star-icon text-start"
          v-show="answerData[0].star"
        ></i>
        <span
          class="text-split text-nav"
          v-html="answerData[0].content"
          :title="answerData[0].star ? '精华回答' : ''"
        ></span>
      </div>
    </el-popover>
    <span v-if="answerData.length <= 0">暂无</span>
  </div>
</template>

<script>
import { GetAnswersList } from "../api/answers";
export default {
  props: {
    questionId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      answerData: [],
    };
  },
  watch: {
    questionId: {
      handler() {
        this.getAnswerList();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    getAnswerList() {
      this.loading = true;
      GetAnswersList({
        page: 0,
        size: 20,
        "question.id": this.questionId,
        star: true,
      })
        .then((res) => {
          this.loading = false;
          let { content } = res.data;
          this.answerData = content;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.table-answer {
  display: flex;
  align-items: center;
  .star-icon {
    margin-right: 5px;
  }
}
.custom-answer-popover {
  width: 100px;
  height: 100px;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>