<template>
  <section class="page-header">
    <div class="left">
      <slot name="left">
        <h3 class="header-title clearfix">
          <span class="title-icon">
            <svg-icon :iconClass="$route.meta.iconSvg"></svg-icon>
          </span>
          {{ $route.meta.title }}
          <span class="title-desc"> {{ desc }}</span>
        </h3>
      </slot>
    </div>
    <div class="right">
      <slot name="right"></slot>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    desc: {
      type: [String],
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.page-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left {
    flex: 1;
    text-align: left;
    .header-title {
      .title-desc {
        font-size: 13px;
        color: #989898;
        text-align: left;
        padding-left: 15px;
      }
    }
  }
  .right {
    flex: 1;
    text-align: right;
  }
}
</style>