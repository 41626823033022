<template>
  <section class="base-page">
    <PageHeader class="base-shadow"></PageHeader>
    <keep-alive><FilterSection @change="onFilterChange" /></keep-alive>
    <basic-table
      hiddenClear
      showSelection
      :loading="tableLoading"
      :tableColumns="tableColumns"
      :tableData="tableData"
      :page="page"
      :size="size"
      :total="total"
      :orderDirection="orderDirection"
      :orderProp="orderProp"
      @onSortChange="onTableSortChange"
      @onSizeChange="handleSizeChange"
      @onPageChange="handlePageChange"
      @onRefresh="onRefresh"
      @onClearAndRefresh="onClearAndRefresh"
      @onSelectionChange="onSelectionChange"
    >
      <template #tableControl>
        <el-button
          class="mt-r"
          size="small"
          type="primary"
          @click="addRow"
          v-if="
            checkPermission([
              'CLIENT_ADMIN',
              'CLIENT_GROUP_ADMIN',
              'CLIENT_USER',
            ])
          "
          >新增</el-button
        >
        <!-- <el-button size="small" class="mt-r" @click="multipleFormDialog = true" >批量新增</el-button> -->
      </template>
      <template v-for="column in tableColumns">
        <el-table-column
          v-if="column.prop !== 'controlColumn' && column.showColumn"
          v-bind="column"
          :show-overflow-tooltip="column.prop === 'answer' ? false : true"
          :key="column.prop"
        >
          <template slot-scope="scope">
            <span v-if="column.prop === 'createdAt'">{{
              scope.row.createdAt | date_time
            }}</span>
            <span v-else-if="column.prop === 'lastModifiedAt'">
              {{ scope.row.createdAt | date_time }}
            </span>
            <span
              v-else-if="column.prop === 'content'"
              class="text-nav"
              @click="showDetail(scope.row)"
            >
              {{ scope.row.content }}
            </span>
            <span v-else-if="column.prop === 'answer'">
              <TableAnswerItem :questionId="scope.row.id" />
            </span>

            <span v-else-if="column.prop === 'disabled'">
              <el-tag type="success" v-if="!scope.row.disabled">已启用</el-tag>
              <el-tag type="danger" v-if="scope.row.disabled">已禁用</el-tag>
            </span>

            <span v-else>{{ scope.row[column.prop] }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="column.prop === 'controlColumn' && column.showColumn"
          v-bind="column"
          :key="column.prop"
        >
          <template slot-scope="scope">
            <el-button
              :loading="scope.row.canUpdateChecking"
              @click.native.prevent="editRow(scope.row, scope.$index)"
              type="text"
              size="small"
            >
              {{ scope.row.canUpdateChecking ? "权限检验中..." : "修改" }}
            </el-button>
            <el-button
              class="text-danger"
              v-if="!scope.row.disabled"
              @click.native.prevent="disableRow(scope.row, scope.$index)"
              type="text"
              size="small"
            >
              {{ scope.row.canDisableChecking ? "权限检验中..." : "禁用" }}
            </el-button>
            <el-button
              class="text-success"
              v-if="scope.row.disabled"
              @click.native.prevent="enableRow(scope.row, scope.$index)"
              type="text"
              size="small"
            >
              {{ scope.row.canDisableChecking ? "权限检验中..." : "启用" }}
            </el-button>
          </template>
        </el-table-column>
      </template>
      <template #multipleControl>
        <el-button
          v-if="
            checkPermission([
              'CLIENT_ADMIN',
              'CLIENT_GROUP_ADMIN',
              'CLIENT_USER',
            ])
          "
          type="success"
          size="small"
          @click="onMultipleToggleDisable(false)"
          :disabled="multipleSelected.length <= 0"
          :loading="multipleUndisableLoading"
          >{{
            multipleUndisableLoading ? multipleDisableLoadingText : "批量启用"
          }}
          {{ multipleSelected.length }}</el-button
        >
        <el-button
          v-if="
            checkPermission([
              'CLIENT_ADMIN',
              'CLIENT_GROUP_ADMIN',
              'CLIENT_USER',
            ])
          "
          type="danger"
          size="small"
          @click="onMultipleToggleDisable(true)"
          :disabled="multipleSelected.length <= 0"
          :loading="multipleDisableLoading"
          >{{
            multipleDisableLoading ? multipleDisableLoadingText : "批量禁用"
          }}
          {{ multipleSelected.length }}</el-button
        >
      </template>
    </basic-table>
    <el-dialog
      :title="isEmprty(currentId) ? '新增问题' : '修改问题'"
      :visible.sync="formDialog"
      append-to-body
      :close-on-click-modal="false"
      width="600px"
    >
      <Form :id="currentId" @cancel="onFormCancel" @success="onFormSuccess" />
    </el-dialog>
  </section>
</template>

<script>
import BasicTable from "@/components/BasicTable";
import Form from "./components/Form";
import { GetQuestionsList, GetUpdateCheck } from "./api/question";
import { ShowApiError } from "@/request/error";
import { onClearFilter } from "@/utils/common";
import { GetTableOrderParams, ReadLocalColumn } from "@/utils/table";
import { isEmprty } from "@/utils/validate";
import { checkPermission } from "@/utils/auth";
import commonControl from "./mixins/commonControl";
import FilterSection from "./components/FilterSection";
import PageHeader from "@/components/PageHeader";
import config from "@/config";
import TableAnswerItem from "./components/TableAnswerItem";
export default {
  components: {
    BasicTable,
    Form,
    FilterSection,
    PageHeader,
    TableAnswerItem,
  },
  mixins: [commonControl],
  data() {
    return {
      formDialog: false,
      tableLoading: false,
      multipleFormDialog: false,
      tableColumns: [],
      page: 0,
      size: config.TABLE_SIZE,
      total: 0,
      tableData: [],
      tableFilters: {},
      orderProp: "createdAt",
      orderDirection: "descending",
      currentId: "",
      multipleSelected: [],
      orderPropObj: {},
    };
  },
  created() {
    this.buildTableColumns();
    this.getTableData();
  },
  methods: {
    isEmprty,
    checkPermission,
    buildTableColumns(columns = []) {
      if (columns.length > 0) {
        this.tableColumns = columns;
      } else {
        let columns = [
          {
            prop: "disabled",
            label: "状态",
            showColumn: true,
            width: 100,
            align: "center",
            fixed: "left",
            sortable: "custom",
          },
          {
            prop: "typeName",
            label: "类型",
            showColumn: true,
            sortable: "custom",
          },
          {
            prop: "content",
            label: "问题",
            minWidth: '150px',
            showColumn: true,
            sortable: "custom",
          },
          {
            prop: "answer",
            label: "精选回答",
            minWidth: '150px',
            showColumn: true,
            sortable: false,
          },
          {
            prop: "createdAt",
            label: "创建日期",
            showColumn: true,
            width: 200,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "createdByName",
            label: "创建人",
            align: "center",
            showColumn: true,
            width: 120,
            sortable: "custom",
          },
          {
            prop: "lastModifiedAt",
            label: "最后修改日期",
            showColumn: true,
            width: 200,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "lastModifiedByName",
            label: "最后修改人",
            align: "center",
            showColumn: true,
            width: 120,
            sortable: "custom",
          },
          {
            prop: "controlColumn",
            fixed: "right",
            showColumn: true,
            label: "操作",
            width: 100,
          },
        ];
        this.tableColumns = ReadLocalColumn(this.$route, columns)
      }
    },
    handleSizeChange(val) {
      this.page = 0;
      this.size = val;
      this.getTableData();
    },
    handlePageChange(val) {
      this.page = val - 1;
      this.getTableData();
    },
    filterParams() {
      return { ...this.tableFilters };
    },
    getTableData() {
      this.tableLoading = true;
      GetQuestionsList({
        page: this.page,
        size: this.size,
        sort: GetTableOrderParams(
          this.orderProp,
          this.orderDirection,
          this.orderPropObj
        ),
        ...this.filterParams(),
      })
        .then((res) => {
          let { content, totalElements } = res.data;
          this.tableData = content.map((item) => {
            return {
              ...item,
              canDisableChecking: false,
              canUpdateChecking: false,
              canUpdate: false,
              canDisable: false,
            };
          });
          this.total = totalElements;
          // 加载状态结束
          this.tableLoading = false;
        })
        .catch((err) => {
          this.tableLoading = false;
          ShowApiError("拉取列表错误", err);
        });
    },
    onRefresh() {
      this.getTableData();
    },
    onClearAndRefresh() {
      this.orderProp = "createdAt";
      this.orderDirection = "descending";
      this.onResetFilter();
      this.onRefresh();
    },
    onResetFilter() {
      this.tableFilters = onClearFilter(this.tableFilters);
    },
    onTableSortChange({ prop, order }) {
      if (order === null) {
        this.orderProp = "createdAt";
        this.orderDirection = "descending";
      } else {
        this.orderProp = prop;
        this.orderDirection = order;
      }
      this.onRefresh();
    },
    onFilterChange(data) {
      this.tableFilters = data;
      this.page = 0;
      this.onRefresh();
    },
    showDetail(row) {
      this.$router.push({
        name: "QuestionDetail",
        params: {
          id: row.id,
        },
      });
    },
    addRow() {
      this.formDialog = true;
    },
    editRow(row) {
      if (row.canUpdate) {
        this.currentId = row.id;
        this.formDialog = true;
        return false;
      }
      row.canUpdateChecking = true;
      GetUpdateCheck(row.id)
        .then((res) => {
          row.canUpdate = res.data;
          if (res.data) {
            this.currentId = row.id;
            this.formDialog = true;
          } else {
            this.currentId = null;
            this.formDialog = false;
            this.$message.warning("没有权限执行此操作!");
          }
        })
        .catch(() => {
          row.canUpdate = false;
          this.$message.warning("权限校验异常，请稍后再试");
        })
        .finally(() => {
          row.canUpdateChecking = false;
        });
    },
    onFormCancel() {
      this.currentId = "";
      this.formDialog = false;
    },
    onFormSuccess() {
      if (this.currentId !== "") {
        this.formDialog = false;
      }
      this.currentId = "";
      this.onRefresh();
    },
    onSelectionChange(selections) {
      this.multipleSelected = selections;
    },
    disableRow(row, index) {
      this.ToggleDisable(row, true).then(() => {
        row.disabled = !row.disabled;
        this.$set(this.tableData, index, row);
      });
    },
    enableRow(row, index) {
      this.ToggleDisable(row, false).then(() => {
        row.disabled = !row.disabled;
        this.$set(this.tableData, index, row);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>